<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Detail Pengumuman</h6>
        </b-col>
        <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
          <base-button @click="back()">Kembali</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <!-- akun -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Overview</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Akun</h5>

              <!-- content -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Tanggal</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>21-10-2011</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Pengumuman</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus autem consequatur quis facilis eligendi non placeat nesciunt sit minus culpa, veniam quidem, dolore dolorem magni deserunt nihil dolores necessitatibus aut!
                    </span>
                  </div>
                </div>

                <h6 class="surtitle py-2" style="margin-top: 100px">Action</h6>
                <div style="display: flex">
                  <base-button @click="back()">Download</base-button>
                  <base-button @click="back()" type="primary">Ubah</base-button>
                  <base-button @click="back()" type="danger">Hapus</base-button>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";

export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
  },
  data() {
    return {
      salesChart: {
        chartData: {
          labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Performance",
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            },
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      ordersChart: {
        chartData: {
          labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
      },
      dotsChart: {
        chartData: {
          labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Performance",
              data: [10, 18, 28, 23, 28, 40, 36, 46, 52],
              pointRadius: 10,
              pointHoverRadius: 15,
              showLine: false,
            },
          ],
        },
        extraOptions: {
          dataset: {
            yAxes: [
              {
                gridLines: {
                  color: Charts.colors.gray[200],
                  zeroLineColor: Charts.colors.gray[200],
                },
              },
            ],
          },
        },
      },
      doughnutChart: {
        chartData: {
          labels: ["Danger", "Warning", "Success", "Primary", "Info"],
          datasets: [
            {
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
              backgroundColor: [
                Charts.colors.theme["danger"],
                Charts.colors.theme["warning"],
                Charts.colors.theme["success"],
                Charts.colors.theme["primary"],
                Charts.colors.theme["info"],
              ],
              label: "Dataset 1",
            },
          ],
        },
        extraOptions: {
          responsive: true,
          legend: {
            position: "top",
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      },
      pieChart: {
        chartData: {
          labels: ["Danger", "Warning", "Success", "Primary", "Info"],
          datasets: [
            {
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
              backgroundColor: [
                Charts.colors.theme["danger"],
                Charts.colors.theme["warning"],
                Charts.colors.theme["success"],
                Charts.colors.theme["primary"],
                Charts.colors.theme["info"],
              ],
              label: "Dataset 1",
            },
          ],
        },
        extraOptions: {
          responsive: true,
          legend: {
            position: "top",
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      },
      barChartStacked: {
        chartData: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "Dataset 1",
              backgroundColor: Charts.colors.theme["danger"],
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
            },
            {
              label: "Dataset 2",
              backgroundColor: Charts.colors.theme["primary"],
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
            },
            {
              label: "Dataset 3",
              backgroundColor: Charts.colors.theme["success"],
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
            },
          ],
        },
        extraOptions: {
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          dataset: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      },
    };
  },
  methods: {
    back() {
      this.$router.push("/pengumuman");
    },
  },
};
</script>
<style></style>
